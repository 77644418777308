import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { goodsCategoryInfo,attrbuteClassValue } from "@/api/goods/goodscategory"
import { addCollect, deleteCollect, isCollect } from "@/api/goods/goods_collect"
import { apiHotSearchWords  } from '@/api/pc';
import {
		cartList as apiCartList
	} from '@/api/goods/cart';
import { getToken } from "@/utils/auth";
import { info } from '@/api/member/info';
export default {
    data: () => {
        return {
            cargoList: [],
            shopList: [], // 店铺列表
            brandList: [], // 品牌列表
            attributeList: [], // 属性列表
            brandInitialList: [],
            currentInitial: "", // 当前选择品牌分区
            choosedBrand: "", // 已选择的品牌,
            hasChoosedAttrs: false, // 忆选择的属性
            total: 0,
            keyword: "",
            catewords:'',
            currentPage: 1,
            pageSize: 12,
            is_free_shipping: 0,
            is_own: "",
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                coupon: 0,
				relatedgoodsid:0
            },
            loading: true,
            whetherCollection: 0,
			first_index:0,
			two_index: 0,
			first_child_list: [],
			first_index_active: 1,
			testingNumberState: false,
			hotSearchWords: [], 
			cateactive:'all',
			cartlist:[]
        }
    },
    created() {
		this.getMInfo();
        this.keyword = this.$route.query.keyword || ""
		if( this.$route.query.keyword ) window.document.title = `${this.$route.query.keyword} - ${this.siteInfo.site_name}`

        this.filters.category_id = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
		this.filters.relatedgoodsid=this.$route.query.gid || 0
		if(this.$route.query.category_id>0){
			this.cateactive='cid-'+this.$route.query.category_id;
		}
        this.getGoodsList();
		this.getCartList();
        if (this.$route.query.category_id) {
			this.first_index_active=this.$route.query.category_id;
            this.categorySearch()
        }
		this.getattrvalue();
		this.getHotSearchWords();
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "siteInfo"])
    },
    methods: {
		getMInfo() {
			var token = getToken();
			if (token){
				info()
					.then(res => { 
						if (res.code == 0) {
							 if(res.data.is_auth==0){
								 
								 this.$message({
								 	message: '您需要先实名认证才能查看交易大厅',
								 	type: 'warning',
								 	duration: 1500,
								 	onClose: () => {
								 		this.$router.push({ path: '/member/memberauth'});
								 	}
								 });
							 }
						}
						 
					})
					.catch(err => {
						 
						 
					});
			}else{
				this.$message({
					message: '请先登录',
					type: 'warning',
					duration: 1500,
					onClose: () => {
						this.$router.push({ path: '/login'});
					}
				});
			}
			
		},
        /**
         * 商品分类搜索
         */
        categorySearch() {
            goodsCategoryInfo({
                category_id: 1,
                goodstype: 1
            }).then(res => {
				console.log(res,'商品分类搜索')
                if (res.code == 0 && res.data) {
                    this.catewords = res.data.category_full_name
					this.first_index = res.data.category_id_1
					this.two_index = res.data.category_id_2
					this.first_child_list = res.data.child_list
					//this.first_index_active= 1
					window.document.title = `${res.data.category_name} - ${this.siteInfo.site_name}`
                }
                
            }).catch(err => {

            })
        },
		getattrvalue(){
			attrbuteClassValue({
                attr_class_id: 1
            }).then(res => { 
                this.attributeList=res.data;
                
            }).catch(err => {

            })
		},
		search() {
			 this.$router.push({
				path: '/spotgoods',
				query: {
					keyword: this.keyword ? this.keyword : this.defaultSearchWords,
					category_id: this.filters.category_id,
					lever:1
				}
			});
			 
		},
		getHotSearchWords() {
			apiHotSearchWords({}).then(res => { 
				if (res.code == 0 && res.data.words) {
					this.hotSearchWords = res.data.words.split(',');
				}
			});
		},
		setFiindex( item ) {
			console.log(item,'setFiindex')
			this.first_index_active = item.category_id
			window.document.title = `${item.category_name} - ${this.siteInfo.site_name}`
		},
		setSiindex() {
			this.first_index_active = 0
		},
		handleClickStatus(tab, event) {
			let cid=0;
			if(tab.name!='all'){
				let tmpcid=tab.name.split("-");
				 cid=tmpcid[1];
				
			}
			
			this.filters.category_id=cid;
			this.getGoodsList();
			 
		},
        addToCart(item) {
            this.$store
                .dispatch('cart/add_to_cart', item)
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => err);
        },
        async isCollect(item) {
            await isCollect({ goods_id: item.goods_id }).then(res => {
                this.whetherCollection = res.data
                if(this.whetherCollection == 0){
                    item.isCollection = false
                }else{
                    item.isCollection = true
                }
            })
                .catch(err => err);
        },
        async editCollection(item) {
           await this.isCollect(item)
            const { goods_id, sku_id, site_id, sku_name, sku_price, sku_image } = item;
            if (this.whetherCollection == 0) {
                await addCollect({ goods_id, sku_id, site_id, sku_name, sku_price, sku_image })
                    .then(res => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success'
                        });
                        item.isCollection = true
                    })
                    .catch(err => err);
            } else {
                await deleteCollect({
                    goods_id
                }).then(res => {
                    if (res.data > 0) {
                        this.$message({
                            message: '取消收藏成功',
                            type: 'success'
                        });
                        item.isCollection = false
                    }
                })
                    .catch(err => err);
            }
        },
        getGoodsList() {
			
            const currentArr = []
			 
            if (this.attributeList) {
                this.attributeList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subitem => {
                            if (subitem.selected) { 
                                currentArr.push({
                                    attr_id: item.attr_id,
                                    attr_value_id: subitem.attr_value_id
                                })
                            }
                        })
                    }
                })
            }

            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                keyword: this.keyword,
				goodstype:1,
                attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
                ...this.filters
            }
			//console.log("------------------",JSON.stringify(params));
            goodsSkuPage(params || {})
                .then(res => {
					//console.log("------------------",JSON.stringify(res));
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },
		getCartList() {
				apiCartList({
					goodstype:1
				})
					.then(res => {
						if (res.code >= 0 && res.data.length) {
							  //console.log("------------------",JSON.stringify(res.data));
							  this.cartlist=res.data;
						 
						}
					})
					.catch(res => {});
			},
		// 删除单个
		deleteCart(cartid) {
			let _this=this;
			this.$confirm("确定要删除该自选商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: cartid
					})
					.then(res => {
						if (res.code >= 0) {
							_this.getCartList(); 
							this.$message({
								type: "success",
								message: "删除成功"
							})
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			})
		},
		gotodetail(skuid){
			localStorage.setItem('seltradeid',this.filters.relatedgoodsid);
			 
			this.$router.pushToTab({ path: '/soptsku-' + skuid });
		},
		buycart(skuid,num){
			var data = {
				sku_id: skuid,
				num: num,
				orderbuytype:1
			}
			
			this.$store.dispatch("order/setOrderCreateData", data)
			this.$router.push({
				path: "/payment"
			})
		},
        onChooseBrand(brand) {
            this.choosedBrand = brand
            this.filters.brand_id = brand.id
            this.getGoodsList()
        },

        setMuiltChoose(attr) {
            this.$set(attr, "isMuiltSelect", !attr.isMuiltSelect)
            this.getGoodsList()
        },

        setAttrSelected(item, subitem) {
            if (item.child) {
                item.child.forEach(attr => {
                    this.$set(attr, "selected", false)
                })
            }

            this.$set(subitem, "selected", true)
            this.$set(item, "selectedValue", subitem.attr_value_name)
            this.getGoodsList()
        },

        setAttrSelectedMuilt(item, subitem) {
            this.$set(subitem, "selected", !subitem.selected)
            var selectedValueArray = []

            if (subitem.selected) {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) == -1) {
                    selectedValueArray.push(subitem.attr_value_name)
                }
            } else {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) !== -1) {
                    selectedValueArray.splice(selectedValueArray.indexOf(subitem.attr_value_name), 1)
                }
            }

            this.$set(item, "selectedValue", selectedValueArray.toString())
            this.getGoodsList()
        },

        colseBrand() {
            this.choosedBrand = ""
            this.filters.brand_id = ""
            this.getGoodsList()
        },

        colseAttr(item) {
            item.selectedValue = ""
            item.child.forEach(subitem => {
                subitem.selected = false
            })

            item.isMuiltSelect = false

            this.getGoodsList()
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        handlePriceRange() {
			if( Number(this.filters.min_price )> Number(this.filters.max_price )){
				// es6解构赋值
				[ this.filters.min_price , this.filters.max_price ] = [ this.filters.max_price , this.filters.min_price ]
			} 
			 
            this.getGoodsList()
        },
        handleChangeInitial(initial) {
            this.currentInitial = initial
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    },
    watch: { 
        is_free_shipping: function (val) {
            this.filters.is_free_shipping = val ? 1 : ""
            this.getGoodsList()
        },
        is_own: function (val) {
            this.filters.is_own = val ? 1 : ""
            this.getGoodsList()
        },
        $route: function (curr) {
			 //console.log("...........22222222222")
			if( curr.query.keyword ) window.document.title = `${curr.query.keyword} - ${this.siteInfo.site_name}`
			if(curr.query.level){
				
				this.filters.category_level = curr.query.level
				this.filters.category_id = curr.query.category_id
				this.keyword=curr.query.keyword
				this.getGoodsList()
				if(curr.query.level != 3){
					this.categorySearch()
				}
			}
            if (curr.query.category_id == undefined) {
                this.catewords = "$route$route"
                this.currentPage = 1
                this.keyword = curr.query.keyword
                this.filters.category_id = curr.query.category_id || ""
                this.filters.category_level = curr.query.level || ""
                this.filters.brand_id = curr.query.brand_id || ""
                this.getGoodsList()
            }
        }
    }
}
